import * as React from 'react';
import { Bell, Inbox } from 'lucide-react';

import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ScrollArea } from '@/components/ui/scroll-area';

import { selectCurrentProfile } from '@/features/auth/auth.selector';
import { useSelector, useDispatch } from '@/hooks/redux.hook';
import { readNotifications } from '@/features/auth/auth.slice';
import notificationApi from '@/api/notification.api';
import { NotificationType } from '@/dtos/notification/notification.dto';
import { SidebarMenuButton, useSidebar } from '@/components/ui/sidebar';
import { NotificationMessage } from '@/components/ui/notification';
import { DropdownMenuSeparator } from '@/components/ui/dropdown-menu';

export const NavNotification = () => {
  const { state, isMobile } = useSidebar();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [hasUnread, setHasUnread] = React.useState(false);
  const [notifications, setNotifications] = React.useState<NotificationType[]>(
    []
  );
  const user = useSelector(selectCurrentProfile);

  React.useEffect(() => {
    notificationApi.findAll().then((response) => {
      setNotifications(response.data);
      setHasUnread(
        user.data.lastNotificationRead
          ? response.data.some(
              (notification) =>
                new Date(notification.createdAt) >
                new Date(user.data.lastNotificationRead)
            )
          : true
      );
    });
  }, [user.data.lastNotificationRead]);

  React.useEffect(() => {
    if (open && hasUnread) {
      dispatch(readNotifications());
      setHasUnread(false);
    }
  }, [open, hasUnread, dispatch]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <SidebarMenuButton
          size={state === 'expanded' ? 'lg' : 'default'}
          className="hover:bg-transparent data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground w-12 h-12 flex items-center justify-center"
        >
          <div className="relative">
            <Bell className="size-4" />

            {hasUnread && (
              <span className="absolute right-[0px] -top-[3px] h-[9px] w-[9px] rounded-full bg-destructive border border-white border-2" />
            )}
          </div>
        </SidebarMenuButton>
      </PopoverTrigger>
      <PopoverContent
        className="w-80 p-0"
        side="bottom"
        align="end"
        sideOffset={8}
        alignOffset={isMobile ? -80 : 0}
      >
        <CardHeader className="px-4 pt-3 pb-2">
          <CardTitle className="text-base">Notificações</CardTitle>
        </CardHeader>

        <DropdownMenuSeparator />

        <ScrollArea className="h-[calc(100vh-theme(space.16))] max-h-[400px]">
          <CardContent className="grid gap-2 p-2 w-full">
            {!notifications.length && (
              <div className="flex w-full h-[300px] gap-2 items-center justify-center text-center text-muted-foreground">
                <Inbox className="size-4" /> Nenhuma notificação
              </div>
            )}
            {notifications.map((notification) => (
              <NotificationMessage
                key={notification.id}
                notification={notification}
                lastRead={user.data.lastNotificationRead}
              />
            ))}
          </CardContent>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
};
