import React, { useState } from 'react';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Formik, Form, Field } from 'formik';
import { useModalAPI } from '../modals.hook';
import { MODALS } from '../modals.constants';
import { useDispatch } from '@/hooks/redux.hook';
import { createMinistry } from '@/features/ministry/ministry.slice';
import { DynamicIcon } from 'lucide-react/dynamic';
import { MinistryRawDTO, MinistryRawType } from '@/dtos/ministry/ministry.dto';
import { IconSelect } from '@/components/ministry/IconSelect.component';
import { Popover, PopoverTrigger } from '@/components/ui/popover';
import { ErrorMessage } from '@/components/ui/error-message';

const MinistryFormSchema = MinistryRawDTO.extend({
  name: z.string().min(1, 'O nome é obrigatório.'),
});

export function MinistryCreate() {
  const { isOpen, close, open } = useModalAPI<MinistryRawType>(
    MODALS.MINISTRY_CREATE
  );
  const { open: openMembers } = useModalAPI(MODALS.MINISTRY_MEMBERS);

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isIconSelectorOpen, setIsIconSelectorOpen] = useState(false);

  const validate = (values: MinistryRawType) => {
    try {
      MinistryFormSchema.parse({
        name: values.name,
        data: { isChurch: values.data.isChurch, icon: values.data.icon },
      });

      return {};
    } catch (error) {
      return (error as z.ZodError).flatten().fieldErrors;
    }
  };

  const handleSubmit = async (values: MinistryRawType) => {
    try {
      setLoading(true);

      await dispatch(createMinistry(values));
    } finally {
      setLoading(false);

      close();
      openMembers();
    }
  };

  const handleOpenChange = () => (isOpen ? close() : open());

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-0 p-0 outline-none">
        <DialogHeader className="px-4 pb-4 pt-4">
          <DialogTitle>Novo ministério</DialogTitle>
          <DialogDescription>
            Essa informação será importante para gerenciar seus eventos.
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={{
            name: '',
            data: { isChurch: false, icon: 'church' },
          }}
          validate={validate}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              <div className="p-4 space-y-8">
                <div className="flex items-center justify-between">
                  <Label htmlFor="icon" className="flex flex-col space-y-1">
                    <span>Avatar</span>
                    <span className="text-xs font-normal leading-snug text-muted-foreground">
                      Ajude a diferenciar seus ministérios internamente.
                    </span>
                  </Label>
                  <Popover
                    open={isIconSelectorOpen}
                    onOpenChange={setIsIconSelectorOpen}
                  >
                    <PopoverTrigger asChild>
                      <Button
                        id="icon"
                        variant="ghost"
                        size="icon"
                        className="aspect-square size-12"
                      >
                        <DynamicIcon
                          name={values.data.icon}
                          className="!size-5"
                        />
                      </Button>
                    </PopoverTrigger>
                    <IconSelect
                      selected={values.data.icon}
                      onSelect={(icon) => {
                        setFieldValue('data.icon', icon);
                        setIsIconSelectorOpen(false);
                      }}
                    />
                  </Popover>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <Label htmlFor="name">Nome</Label>
                  <Field name="name">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        placeholder="Nome da Igreja ou Ministério"
                        className={
                          meta.touched && meta.error ? 'border-red-500' : ''
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage name="name" />
                </div>

                <div className="flex items-center justify-between pt-2">
                  <Label className="flex flex-col space-y-1">
                    <span>Esse ministério é uma igreja?</span>
                    <span className="text-xs font-normal leading-snug text-muted-foreground">
                      Tenha acesso a funcionalidades exclusivas.
                    </span>
                  </Label>
                  <Switch
                    aria-label="is-church"
                    checked={values.data.isChurch}
                    onCheckedChange={(checked) =>
                      setFieldValue('data.isChurch', checked)
                    }
                  />
                </div>
              </div>
              <DialogFooter className="flex items-end border-t p-4 sm:justify-end mt-8">
                <Button
                  className="w-28"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  onClick={submitForm}
                >
                  Criar
                </Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
