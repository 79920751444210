import React, { useState } from 'react';
import { z } from 'zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Formik, Form, Field } from 'formik';
import { useModalAPI } from '../modals.hook';
import { MODALS } from '../modals.constants';
import { useDispatch, useSelector } from '@/hooks/redux.hook';
import {
  deleteMinistry,
  updateMinistry,
} from '@/features/ministry/ministry.slice';
import { DynamicIcon } from 'lucide-react/dynamic';
import {
  MinistryRawDTO,
  MinistryRawType,
  MinistryType,
} from '@/dtos/ministry/ministry.dto';
import { IconSelect } from '@/components/ministry/IconSelect.component';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { ErrorMessage } from '@/components/ui/error-message';

import { CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { selectCurrentProfile } from '@/features/auth/auth.selector';
import { getCurrentMinistry } from '@/features/ministry/minsitry.selector';

const MinistryFormSchema = MinistryRawDTO.extend({
  name: z.string().min(1, 'O nome é obrigatório.'),
});

export const MinistryEdit = () => {
  const { isOpen, close, open, state } = useModalAPI<MinistryType>(
    MODALS.MINISTRY_EDIT
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isIconSelectorOpen, setIsIconSelectorOpen] = useState(false);
  const currentProfile = useSelector(selectCurrentProfile);
  const currentMinistry = useSelector(getCurrentMinistry);
  const isOwner = currentMinistry.createdBy === currentProfile.id;

  console.log(currentMinistry, currentProfile, isOwner);

  const validate = (values: MinistryRawType) => {
    try {
      MinistryFormSchema.parse({
        name: values.name,
        data: { isChurch: values.data.isChurch, icon: values.data.icon },
      });

      return {};
    } catch (error) {
      return (error as z.ZodError).flatten().fieldErrors;
    }
  };

  const handleSubmit = async (id: string, values: Partial<MinistryRawType>) => {
    if (loading) return;

    try {
      setLoading(true);

      await dispatch(updateMinistry({ id, ministry: values }));
    } finally {
      setLoading(false);

      close();
    }
  };

  const handleDelete = async () => {
    if (loading) return;

    try {
      setLoading(true);

      await dispatch(deleteMinistry(state.id));
    } finally {
      setLoading(false);

      close();
    }
  };

  const handleOpenChange = () => (isOpen ? close() : open());

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-0 p-0 outline-none">
        <DialogHeader className="px-4 pb-4 pt-4">
          <DialogTitle>Editar ministério</DialogTitle>
          <DialogDescription>
            Edite os dados do seu ministério.
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={state}
          validate={validate}
          validateOnBlur={false}
          onSubmit={(values) => handleSubmit(state.id, values)}
        >
          {({ values, setFieldValue, submitForm }) => (
            <Form>
              <div className="p-4 space-y-8">
                <div className="flex items-center justify-between">
                  <Label htmlFor="icon" className="flex flex-col space-y-1">
                    <span>Avatar</span>
                    <span className="text-xs font-normal leading-snug text-muted-foreground">
                      Ajude a diferenciar seus ministérios internamente.
                    </span>
                  </Label>
                  <Popover
                    open={isIconSelectorOpen}
                    onOpenChange={setIsIconSelectorOpen}
                  >
                    <PopoverTrigger asChild>
                      <Button
                        id="icon"
                        variant="ghost"
                        size="icon"
                        className="aspect-square size-12"
                      >
                        <DynamicIcon
                          name={values.data.icon}
                          className="!size-5"
                        />
                      </Button>
                    </PopoverTrigger>
                    <IconSelect
                      selected={values.data.icon}
                      onSelect={(icon) => {
                        setFieldValue('data.icon', icon);
                        setIsIconSelectorOpen(false);
                      }}
                    />
                  </Popover>
                </div>
                <div className="flex flex-col w-full gap-2">
                  <Label htmlFor="name">Nome</Label>
                  <Field name="name">
                    {({ field, meta }: any) => (
                      <Input
                        {...field}
                        id="name"
                        type="text"
                        placeholder="Nome da Igreja ou Ministério"
                        className={
                          meta.touched && meta.error ? 'border-red-500' : ''
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage name="name" />
                </div>
                {isOwner && (
                  <div className="flex items-center justify-between bg-destructive/10 p-4 rounded-lg">
                    <Label htmlFor="icon" className="flex flex-col space-y-1">
                      <span>Remoção do Ministério</span>
                      <span className="text-xs font-normal leading-snug text-muted-foreground">
                        Quero remover esse ministério permanentemente.
                      </span>
                    </Label>

                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="ghost"
                          className="text-destructive hover:bg-destructive/60 hover:text-white"
                        >
                          Deletar
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-80 border-none">
                        <CardHeader className="p-0">
                          <CardTitle>Tem certeza?</CardTitle>
                          <CardDescription>
                            Essa ação é irreversível e não pode ser desfeita. Os
                            eventos e membros serão perdidos.
                          </CardDescription>

                          <div className="flex justify-end">
                            <Button
                              variant="destructive"
                              className="w-20 mt-2"
                              disabled={loading}
                              loading={loading}
                              onClick={handleDelete}
                            >
                              Deletar
                            </Button>
                          </div>
                        </CardHeader>
                      </PopoverContent>
                    </Popover>
                  </div>
                )}
              </div>
              <DialogFooter className="flex items-end border-t p-4 sm:justify-end mt-8">
                <Button
                  className="w-28"
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  onClick={submitForm}
                >
                  Editar
                </Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
