import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import supabaseAuth from '../../services/supabase';
import { AuthState, User, SignInMethod, LoadingStatus } from './auth.interface';
import * as toast from '@/utils/notifications.util';
import authApi from '../../api/auth.api';
import notificationApi from '../../api/notification.api';

const initialState: AuthState = {
  signInMethod: null,
  profile: null,
  token: null,
  user: null,
  status: LoadingStatus.IDLE,
};

export const findOrCreateProfile = createAsyncThunk(
  'auth/findOrCreateProfile',
  async () => {
    try {
      const { data } = await authApi.findOneProfileOrCreate();

      return data;
    } catch (error) {
      throw error;
    }
  }
);

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async () => {
    const { data, error } = await supabaseAuth.signInWithGoogle();

    if (error) throw error;

    return data;
  }
);

export const login = createAsyncThunk(
  'auth/login',
  async ({ email, password }: { email: string; password: string }) => {
    const { data, error } = await supabaseAuth.signIn(email, password);

    if (error) throw error;

    return data;
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  const { error } = await supabaseAuth.signOut();

  if (error) throw error;

  return;
});

export const readNotifications = createAsyncThunk(
  'auth/readNotifications',
  async () => {
    try {
      await notificationApi.read();

      return;
    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<{ user: User; token: string }>) {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLoading(state, action: PayloadAction<LoadingStatus>) {
      state.status = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // Handle findOrCreateProfile
      .addCase(findOrCreateProfile.pending, (state) => {
        state.status = LoadingStatus.LOADING;
      })
      .addCase(findOrCreateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.signInMethod = null;
        state.status = LoadingStatus.SUCCEEDED;
      })
      .addCase(findOrCreateProfile.rejected, (state) => {
        toast.error({
          title: 'Erro ao carregar seu perfil',
          subtitle: 'Tente novamente mais tarde',
        });
      })

      // Handle login with Google
      .addCase(loginWithGoogle.pending, (state) => {
        state.signInMethod = SignInMethod.GOOGLE;
        state.status = LoadingStatus.LOADING;
      })
      .addCase(loginWithGoogle.rejected, (state) => {
        state.profile = null;
        state.user = null;
        state.token = null;
        state.signInMethod = null;
        state.status = LoadingStatus.IDLE;

        toast.error({
          title: 'Login falhou',
          subtitle: 'Tente novamente',
        });
      })

      // Handle login
      .addCase(login.pending, (state) => {
        state.signInMethod = SignInMethod.EMAIL;
        state.status = LoadingStatus.LOADING;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.token = action.payload.session.access_token;
      })
      .addCase(login.rejected, (state) => {
        state.profile = null;
        state.user = null;
        state.token = null;
        state.signInMethod = null;
        state.status = LoadingStatus.IDLE;

        toast.error({
          title: 'Login falhou',
          subtitle: 'Email ou senha incorretos',
        });
      })

      // Read notifications
      .addCase(readNotifications.fulfilled, (state) => {
        state.profile = {
          ...state.profile,
          data: {
            ...state.profile.data,
            lastNotificationRead: new Date().toISOString(),
          },
        };
      })

      // Handle logout
      .addCase(logout.fulfilled, (state) => {
        state.profile = null;
        state.user = null;
        state.token = null;
        state.signInMethod = null;
        state.status = LoadingStatus.IDLE;
      });
  },
});

export const { setUser, setLoading } = authSlice.actions;

export default authSlice.reducer;
