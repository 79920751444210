import React from 'react';
import { SidebarTrigger } from '@/components/ui/sidebar';
import { Separator } from '@radix-ui/react-separator';
import BreadcrumbBuilder from '@/components/dashboard/BreadcrumbBuilder.component';
import { NavUser } from './NavUser.component';
import { NavNotification } from './NavNotification.component';

export const DashboardHeader: React.FC<{
  breadcrumbs: { name: string; link?: string }[];
}> = ({ breadcrumbs }) => {
  return (
    <header className="flex h-16 w-full shrink-0 items-center justify-between transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div className="flex items-center gap-2 pl-4">
        <SidebarTrigger className="-ml-1" />
        <Separator orientation="vertical" className="mr-2 h-4" />
        <BreadcrumbBuilder items={breadcrumbs} />
      </div>

      <div className="pr-4 flex items-center gap-2">
        <NavNotification />
        <NavUser />
      </div>
    </header>
  );
};
