import * as React from 'react';
import { ChevronsUpDown, Plus, Settings, UserPlus } from 'lucide-react';
import { DynamicIcon } from 'lucide-react/dynamic';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import { useDispatch, useSelector } from '@/hooks/redux.hook';
import {
  fetchMinistries,
  setCurrentMinistry,
  getMinistryInsight,
} from '@/features/ministry/ministry.slice';
import { RootState } from '@/app/store';
import { MinistrySkeleton } from '@/components/skeletons/ministry.component';
import { LOADING_STATES } from '@/constants/loading-states.constant';
import { MODALS } from '@/components/modals/modals.constants';
import { useModalAPI } from '@/components/modals/modals.hook';
import {
  getCurrentMinistry,
  getCurrentMinistryInsight,
  getMinistryList,
} from '@/features/ministry/minsitry.selector';
import { MinistryItem } from './MinistryItem.component';
import { Button } from '@/components/ui/button';
import { MinistryRawType, MinistryType } from '@/dtos/ministry/ministry.dto';
import { TooltipWithContent } from '@/components/ui/tooltip';
import { InsightSkeleton } from '@/components/skeletons/insight.component';

const MINISTRY_LIMIT = 5;

export function Ministries() {
  const editModal = useModalAPI<MinistryType>(MODALS.MINISTRY_EDIT);
  const createModal = useModalAPI<MinistryRawType>(MODALS.MINISTRY_CREATE);
  const ministryMembersModal = useModalAPI(MODALS.MINISTRY_MEMBERS);
  const dispatch = useDispatch();
  const ministry = useSelector((state: RootState) => state.ministry);
  const currentMinistry = useSelector(getCurrentMinistry);
  const currentMinistryInsight = useSelector(getCurrentMinistryInsight);
  const allMinistries = useSelector(getMinistryList);
  const hasReachedLimit = useSelector(getMinistryList).length >= MINISTRY_LIMIT;

  React.useEffect(() => {
    dispatch(fetchMinistries());
  }, [dispatch]);

  React.useEffect(() => {
    if (currentMinistry.id) {
      dispatch(getMinistryInsight(currentMinistry.id));
    }
  }, [currentMinistry.id, dispatch]);

  if (ministry.status === LOADING_STATES.LOADING && !currentMinistry) {
    return <MinistrySkeleton />;
  }

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        {!currentMinistry.id ? (
          <SidebarMenuButton
            size="lg"
            className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            onClick={() => createModal.open()}
          >
            <div className="grid flex-1 text-left text-sm leading-tight">
              <span className="truncate">Criar ministério</span>
            </div>
            <div className="flex aspect-square size-8 items-center justify-center">
              <Plus className="size-4" />
            </div>
          </SidebarMenuButton>
        ) : (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-gradient-to-tl from-teal-200/50 to-green-300/50">
                  <DynamicIcon
                    name={currentMinistry.data.icon}
                    className="size-4"
                  />
                </div>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {currentMinistry.name}
                  </span>
                  <span className="truncate text-xs">Ministério</span>
                </div>
                <ChevronsUpDown className="ml-auto" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-80 rounded-lg"
              align="start"
              side="bottom"
              sideOffset={8}
            >
              <div className="flex items-center gap-2 p-2">
                <div className="flex aspect-square size-10 items-center justify-center rounded-lg bg-gradient-to-tl from-teal-200/50 to-green-300/50">
                  <DynamicIcon
                    name={currentMinistry.data.icon}
                    className="size-5"
                  />
                </div>
                <div className="grid gap-[2px] flex-1 text-left text-sm">
                  <span className="truncate font-semibold">
                    {currentMinistry.name}
                  </span>
                  <div className="text-xs text-muted-foreground">
                    {currentMinistryInsight ? (
                      <>
                        <span className="">
                          {currentMinistryInsight.adminCount} admin
                        </span>
                        <span className="relative mx-1 text-muted-foreground/50 top-[1px]">
                          •
                        </span>
                        <span className="">
                          {currentMinistryInsight.eventCount} Eventos
                        </span>
                      </>
                    ) : (
                      <InsightSkeleton />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2 p-2 pt-0 text-muted-foreground">
                <Button
                  className="h-7"
                  variant="outline"
                  size="sm"
                  onClick={() => editModal.open(currentMinistry)}
                >
                  <Settings className="!size-3" />
                  Editar
                </Button>
                <Button
                  className="h-7"
                  variant="outline"
                  size="sm"
                  onClick={() => ministryMembersModal.open()}
                >
                  <UserPlus className="!size-3" />
                  Convidar administradores
                </Button>
              </div>

              <DropdownMenuSeparator />
              <DropdownMenuLabel className="text-xs text-muted-foreground">
                Ministérios
              </DropdownMenuLabel>
              {allMinistries.map((ministry) => (
                <React.Fragment key={ministry.id}>
                  <MinistryItem
                    ministry={ministry}
                    isActive={currentMinistry.id === ministry.id}
                    onClick={() => dispatch(setCurrentMinistry(ministry.id))}
                  />
                </React.Fragment>
              ))}
              <DropdownMenuSeparator />

              <TooltipWithContent
                disabled={!hasReachedLimit}
                content={`Você atingiu o limite de ${MINISTRY_LIMIT} ministérios.`}
              >
                <div>
                  <DropdownMenuItem
                    disabled={hasReachedLimit}
                    onClick={() => createModal.open()}
                    className="gap-2 p-2"
                  >
                    <div className="flex size-6 items-center justify-center">
                      <Plus className="size-4" />
                    </div>
                    Novo ministério
                  </DropdownMenuItem>
                </div>
              </TooltipWithContent>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </SidebarMenuItem>
    </SidebarMenu>
  );
}
