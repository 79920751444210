import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';

import { Button } from '@/components/ui/button';

import { useModalAPI } from '../modals.hook';
import { MODALS } from '../modals.constants';
import { ministryAPI } from '@/api/ministry.api';
import { getCurrentMinistry } from '@/features/ministry/minsitry.selector';
import { useSelector } from '@/hooks/redux.hook';
import {
  MinistryMemberRoleSchema,
  MinistryMembersType,
  PopulatedMinistryMembersType,
} from '@/dtos/ministry/ministry.dto';
import { Input } from '@/components/ui/input';
import { selectCurrentProfile } from '@/features/auth/auth.selector';
import { UserSkeleton } from '@/components/skeletons/user.component';
import { MinistryMemberUser } from './components/MinistryMemberUser.component';
import { MinistryMemberIncognito } from './components/MinistryMemberIncognito.component';
import { toast } from 'sonner';
import { HttpStatusCode } from 'axios';

const isPopulated = (
  member: MinistryMembersType | PopulatedMinistryMembersType
): member is PopulatedMinistryMembersType => {
  return !!(member as PopulatedMinistryMembersType).profile;
};

export const MinistryMembers = () => {
  const [loading, setLoading] = useState(false);
  const { isOpen, close, open } = useModalAPI(MODALS.MINISTRY_MEMBERS);
  const [search, setSearch] = useState('');
  const [members, setMembers] = useState<
    Array<PopulatedMinistryMembersType | MinistryMembersType>
  >([]);
  const currentMinistry = useSelector(getCurrentMinistry);
  const currentProfile = useSelector(selectCurrentProfile);

  const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(search);

  const getAllMembers = useCallback(() => {
    setMembers([]);

    ministryAPI.getAllMembers(currentMinistry.id).then((members) => {
      setMembers(members.data);
    });
  }, [currentMinistry.id]);

  const inviteMember = useCallback(() => {
    ministryAPI
      .inviteMember(currentMinistry.id, {
        email: search,
        role: MinistryMemberRoleSchema.ADMIN,
      })
      .then((member) => {
        setMembers((prev) => [...prev, member.data]);
        setLoading(false);
        setSearch('');
        toast.success('Administrador convidado');
      })
      .catch((err) => {
        setLoading(false);

        if (err.response.status === HttpStatusCode.Conflict) {
          return toast.error('Este usuário já faz parte do ministério');
        }

        toast.error('Erro ao convidar administrador');
      });
  }, [currentMinistry.id, search]);

  useEffect(() => {
    if (currentMinistry && isOpen) {
      getAllMembers();
    }
  }, [currentMinistry, isOpen, getAllMembers]);

  const handleInvite = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
    inviteMember();
  };

  const handleOpenChange = () => (isOpen ? close() : open());

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-2 p-0 outline-none">
        <DialogHeader className="px-4 pt-4">
          <DialogTitle>Administradores do ministério</DialogTitle>
          <DialogDescription>
            Adicione pessoas que poderão ver e gerenciar todos os eventos.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-4 p-4 pb-0">
          <form className="flex items-center" onSubmit={handleInvite}>
            <Input
              placeholder="Email"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            <Button
              disabled={!isValidEmail}
              onClick={handleInvite}
              className="ml-2 w-28"
              loading={loading}
              type="submit"
            >
              Convidar
            </Button>
          </form>
        </div>

        <div className="flex flex-col gap-4 p-4">
          {!members.length && <UserSkeleton />}
          {!!members.length &&
            members.map((member) => {
              return isPopulated(member) ? (
                <MinistryMemberUser
                  key={member.profile.id}
                  profile={member.profile}
                  currentProfile={currentProfile}
                  role={member.role}
                />
              ) : (
                <MinistryMemberIncognito
                  key={member.email}
                  email={member.email}
                  role={member.role}
                />
              );
            })}
        </div>

        <DialogFooter className="flex items-end border-t p-4 sm:justify-end">
          <Button onClick={() => close()}>Fechar</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
