export const isLessThanOneMinuteAgo = (date: Date): boolean => {
  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return diff < 60000;
};

export const getRelativeTime = (date: string): string => {
  const rtf = new Intl.RelativeTimeFormat('pt', { numeric: 'auto' });
  const now = new Date();
  const then = new Date(date);
  const diff = then.getTime() - now.getTime();
  const absDiff = Math.abs(diff);

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day;
  const year = 12 * month;

  if (absDiff < minute) {
    return 'agora';
  }

  let value: number;
  let unit: Intl.RelativeTimeFormatUnit;

  if (absDiff < hour) {
    value = Math.round(diff / minute);
    unit = 'minute';
  } else if (absDiff < day) {
    value = Math.round(diff / hour);
    unit = 'hour';
  } else if (absDiff < month) {
    value = Math.round(diff / day);
    unit = 'day';
  } else if (absDiff < year) {
    value = Math.round(diff / month);
    unit = 'month';
  } else {
    value = Math.round(diff / year);
    unit = 'year';
  }

  return rtf.format(value, unit);
};
