/* eslint-disable @typescript-eslint/no-redeclare */
import { z } from "zod";
import { BaseDTO } from "../base.dtos";
import { IconName } from "lucide-react/dynamic";
import { ProfileType } from "../auth/profile.dto";

export const MINISTRY_ICONS = [
  "church",
  "home",
  "graduation-cap",
  "book-open",
  "flame",
  "earth",
  "heart",
  "wind",
  "droplet",
  "guitar",
  "mic-vocal",
  "drum",
  "clapperboard",
  "camera",
  "biceps-flexed",
  "traffic-cone",
  "coffee",
  "pizza",
  "monitor-smartphone",
  "megaphone",
] as const satisfies readonly IconName[];

export type MinistryIcon = (typeof MINISTRY_ICONS)[number];

export const MinistryRawDTO = z.object({
  name: z.string(),
  data: z.object({
    isChurch: z.boolean(),
    icon: z.enum(MINISTRY_ICONS).nullable(),
  }),
});

export type MinistryRawType = z.infer<typeof MinistryRawDTO>;

export const MinistryDTO = BaseDTO.merge(MinistryRawDTO);

export type MinistryType = z.infer<typeof MinistryDTO>;

export const MinistryMemberRoleSchema = {
  ADMIN: "admin",
  MEMBER: "member",
} as const;
export type MinistryMemberRole =
  (typeof MinistryMemberRoleSchema)[keyof typeof MinistryMemberRoleSchema];

export const MinistryMemberStatusSchema = {
  ACTIVE: "active",
  INACTIVE: "inactive",
} as const;
export type MinistryMemberStatus =
  (typeof MinistryMemberStatusSchema)[keyof typeof MinistryMemberStatusSchema];

export const MinistryMembersDTO = BaseDTO.merge(
  z.object({
    ministryID: z.string(),
    profileID: z.string().nullable(),
    email: z.string(),
    status: z.nativeEnum(MinistryMemberStatusSchema),
    role: z.nativeEnum(MinistryMemberRoleSchema),
  })
);

export type MinistryMembersType = z.infer<typeof MinistryMembersDTO>;
export type PopulatedMinistryMembersType = MinistryMembersType & {
  profile: ProfileType;
};

export const MinistryInsight = z
  .object({
    adminCount: z.number(),
    eventCount: z.number(),
  })
  .strict();

export type MinistryInsightType = z.infer<typeof MinistryInsight>;
