import { RootState } from '@/app/store';
import { createSelector } from '@reduxjs/toolkit';

export const selectMinistryState = (state: RootState) => state.ministry;

export const getMinistryList = createSelector(
  [selectMinistryState],
  (ministryState) => ministryState.list
);

export const createGetOneMinistry = () =>
  createSelector([getMinistryList], (ministries) => {
    return (args: { id: string }) =>
      ministries.find((ministry) => ministry.id === args.id) || null;
  });

export const getCurrentMinistry = createSelector(
  [selectMinistryState],
  (ministryState) =>
    ministryState.list.find(
      (ministry) => ministry.id === ministryState.current
    ) || {}
);

export const getCurrentMinistryInsight = createSelector(
  [selectMinistryState],
  (ministryState) => ministryState.insight
);
