import { Skeleton } from '@/components/ui/skeleton';

export function InsightSkeleton() {
  return (
    <div className="flex items-center justify-start w-full mt-[2px]">
      <Skeleton className="h-[14px] w-[42px] rounded-sm" />
      <Skeleton className="size-[3px] rounded-full mt-[2px] mx-[7px]" />
      <Skeleton className="h-[14px] w-[65px] rounded-sm" />
    </div>
  );
}
