import * as React from 'react';
import { match } from 'ts-pattern';

import {
  NotificationType,
  BatchSoldOutType,
  MinistryInviteType,
} from '@/dtos/notification/notification.dto';
import { NOTIFICATION_TYPES } from '@/dtos/notification/types/types.dto';
import { getRelativeTime } from '@/utils/time.util';

export const NotificationMessage: React.FC<{
  notification: NotificationType;
  lastRead: string;
}> = ({ notification, lastRead }) => {
  const [isNew] = React.useState(
    lastRead ? new Date(notification.createdAt) > new Date(lastRead) : true
  );

  const data = notification.data as NotificationType['data'];

  return match<NotificationType['data']>(data)
    .with(
      { type: NOTIFICATION_TYPES.MINISTRY_INVITE } as const,
      (data: MinistryInviteType) => (
        <MinistryInviteNotification
          data={data}
          isNew={isNew}
          createdAt={notification.createdAt}
        />
      )
    )
    .with(
      { type: NOTIFICATION_TYPES.BATCH_SOLD_OUT } as const,
      (data: BatchSoldOutType) => <BatchSoldOutNotification data={data} />
    )
    .otherwise(() => null);
};

export const MinistryInviteNotification: React.FC<{
  data: MinistryInviteType;
  isNew: boolean;
  createdAt: string;
}> = ({ data, isNew, createdAt }) => {
  return (
    <div className="flex flex-col gap-2 rounded-lg p-3 text-left text-sm">
      <div className="flex items-start gap-2">
        {isNew && (
          <span className="mt-1.5 h-2 w-2 shrink-0 rounded-full bg-destructive" />
        )}
        <div className="grid gap-1">
          <div className="font-medium">Novo ministério!</div>
          <div className="text-muted-foreground line-clamp-2">
            <strong>{data.senderName}</strong> te adicionou como {data.role} no
            ministério <strong>{data.ministryName}</strong>
          </div>
          <div className="text-xs text-muted-foreground">
            {getRelativeTime(createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export const BatchSoldOutNotification: React.FC<{ data: BatchSoldOutType }> = ({
  data,
}) => {
  return null;
};
